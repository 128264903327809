// jshint ignore: start
import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import { Typography, List, ListItem } from '@material-ui/core';
import { SocialIcon } from 'react-social-icons';
import { Link, useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        height: 'auto',
        backgroundColor: "lightgray"
    },
    container: {
        color: '#000',
        textAlign: 'center',
        backgroundColor: '#f6f6f6',
        padding: '1.5em 0 1em 0'
    },
    aitem: {
        padding: 5,
        whiteSpace: 'nowrap',
        color: 'rgba(255, 255, 255, .75)',
        fontSize: '.9em',
        display: 'inline-block',
        width: 'auto'
    },
    link: {
        color: '#333232',
        textDecoration: 'none',
        '&:hover': {
            color: '#5a5858',
            borderBottom: '1px solid #5a5858',
        }
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
        maxWidth: 500,
        margin: '0 auto',
        justifyContent: 'space-around',
        listStyle: 'none'
    },
    listItem: {
        display: 'inline-block',
        textAlign: 'center',
        marginBottom: 0
    },
    social: {
        margin: 4
    }
}));

const Footer = ({ children }) => {
    const classes = useStyles();
    
    const data = useStaticQuery(graphql`
        query {
            footerloguinho: file(relativePath: { eq: "site-logo.jpg" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE)
                }
            }
            livro: file(relativePath: { eq: "livro.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 116, placeholder: NONE)
                }
            }
        }
    `)

    return (
        <div className={classes.root}>
            <div className={classes.container}>
                <a href="/">
                    <GatsbyImage alt="" loading="eager" style={{margin: '0 auto'}} image={data.footerloguinho.childImageSharp.gatsbyImageData} />
                </a>
                <List className={classes.list}>
                    <ListItem className={classes.listItem}>
                        <Link className={classes.link} to="/privacidade"><Typography component="span">Política de Privacidade</Typography></Link>
                    </ListItem>
                    <ListItem className={classes.listItem}>
                        <Link className={classes.link} to="/termos"><Typography component="span">Termos e Condições</Typography></Link>
                    </ListItem>
                </List>
                <div style={{padding: '20px 0'}}>
                    <SocialIcon className={classes.social} url="https://www.facebook.com/vetespinho" />
                    <SocialIcon className={classes.social} url="https://www.instagram.com/vetespinhoclinica/" />
                    <a role="button" aria-label="Livro de reclamações" href="https://www.livroreclamacoes.pt/inicio"><GatsbyImage alt="" loading="eager" style={{margin: '0 auto'}} image={data.livro.childImageSharp.gatsbyImageData} /></a>
                </div>
                <Typography variant="caption" style={{marginBottom: 20}} component="p">© 2021 Vetespinho. Todos os direitos reservados. - Powered By Appuro Inc.</Typography>
            </div>
        </div>
    )
}

export default Footer